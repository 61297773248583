<template>
  <div class="payment-details p-3 pb-5 p-md-5 w-75">
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="6" order="1" order-md="2" class="pl-0 text-md-right">
          <ul class="list-unstyled list-inline larkin-breadcrumb pb-4 mb-0" style="font-size: 16px !important; font-weight: 300">
            <li class="list-inline-item">
              <b-link :to="{name: 'payments'}" class="breadcrumb-link"><span class="icon icon-arrow-left8"></span> <span> Back</span></b-link>
            </li>
            <li class="list-inline-item">|</li>
            <li class="list-inline-item">Payment # {{payment.id}}</li>
          </ul>
        </b-col>
        <b-col cols="12" md="6" order="2" order-md="1" class="pl-0">
          <h4>Payment details</h4>
        </b-col>
      </b-row>
    </b-container>

    <address v-if="payment.client" style="font-size: 18px;">
      <img v-if="logoSrc" alt="img" :src="logoSrc" style="width:150px;" /> <br />
      {{ payment.client.address }} <br/>
      {{ payment.client.address2 }} <br v-if="payment.client.address2"/>
      {{ payment.client.city }}, {{ payment.client.state }} {{ payment.client.postalCode }} <br/>
    </address>

    <b-container fluid class="col-data-box p-0">
      <b-row class="col-highlight header mb-3">
        <b-col>Claim Information</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">Claimant:</b-col>
        <b-col class="payment-detail-value" cols="9">{{ payment.firstName }} {{ payment.lastName }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">ID:</b-col>
        <b-col class="payment-detail-value" cols="9">{{ payment.employeeId }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">Plan #:</b-col>
        <b-col class="payment-detail-value" cols="9">{{ payment.planNumber }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">Plan Name:</b-col>
        <b-col class="payment-detail-value" cols="9">{{ payment.planName }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">Claim #:</b-col>
        <b-col class="payment-detail-value" cols="9">{{ payment.claimId }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">Payment #:</b-col>
        <b-col class="payment-detail-value" cols="9">{{ payment.checkNo }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">Date(s):</b-col>
        <b-col class="payment-detail-value" cols="9">{{ payment.date }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="2">Comments:</b-col>
        <b-col class="payment-detail-value" cols="9" style="white-space: normal !important">{{ payment.checkComments }}</b-col>
      </b-row>
    </b-container>

    <b-container fluid class="col-data-box p-0 mt-4">
      <b-row class="col-highlight header" v-if="!payment.detailedBenefitPayment">
        <b-col cols="9" class="p-0">Payment Items</b-col>
        <b-col cols="3" class="p-0">Amount</b-col>
      </b-row>

      <!-- Loop through all the line items -->
      <div v-for="(lineItem, index) in payment.lineItems" v-bind:key="index" class="my-2 py-1 payment-line-items">
        <b-row>
          <b-col class="payment-detail-key" cols="2">Type:</b-col>
          <b-col class="payment-detail-value"  cols="6">{{ lineItem.type }}</b-col>
          <b-col cols="3"></b-col>
        </b-row>
        <b-row>
          <b-col class="payment-detail-key" cols="2">Date:</b-col>
          <b-col class="payment-detail-value" cols="7">{{lineItem.startDate}} - {{lineItem.endDate}}</b-col>
          <b-col class="justify-content-end payment-detail-value" cols="3">{{ lineItem.amountFormatted }}</b-col>
        </b-row>
        <b-row>
          <b-col class="payment-detail-key" cols="2">Comment:</b-col>
          <b-col class="payment-detail-value" cols="9" style="white-space: normal !important">{{ lineItem.comment }}</b-col>
        </b-row>
      </div>

      <b-row class="col-highlight header" v-if="payment.detailedBenefitPayment">
        <b-col cols="12" class="p-0">Payment Details</b-col>
      </b-row>

      <table v-if="payment.detailedBenefitPayment" class="payment-detail">
        <template v-for="payrollPeriod in payment.payrollPeriods">

          <template v-for="paymentSegment in payrollPeriod.paymentSegments">
            <tr>
              <td colspan="3" class="payment-segment-dates">{{paymentSegment.fromDate}} - {{paymentSegment.throughDate}}</td>
              <td colspan="3" class="space"></td>
            </tr>

            <tr v-for="benefitPayment in paymentSegment.benefitLineItems" class="payment-segment">
              <td colspan="2" :class="'benefit-payment-comment ' + evenOrOdd('reset')">{{benefitPayment.comment}}</td>
              <td :class="'amount benefit-payment-amount ' + evenOrOdd()">{{benefitPayment.amountFormatted}}</td>
              <td colspan="3" class="space"></td>
            </tr>

            <tr v-if="paymentSegment.reductionLineItems.length">
              <td colspan="3" class="payment-segment-divider"></td>
              <td colspan="3" class="payment-segment-divider"></td>
            </tr>
            
            <tr v-if="paymentSegment.reductionLineItems.length && !paymentSegment.reducibleAmountExplanation" v-for="reduction in paymentSegment.reductionLineItems">
              <td :class="'reduction-comment ' + evenOrOdd('flip')">{{reduction.comment}} ({{reduction.startDate}} - {{reduction.endDate}})</td>
              <td :class="'amount reduction-amount ' + evenOrOdd() + amountClass(reduction.amountFormatted)">{{reduction.amountFormatted}}</td>
              <td colspan="4" class="space"></td>
            </tr>

            <tr v-if="paymentSegment.reductionLineItems.length && paymentSegment.reducibleAmountExplanation" v-for="reduction in paymentSegment.reductionLineItems">
              <td colspan="3" :class="'reduction-comment ' + evenOrOdd()">{{reduction.comment}}</td>
              <td colspan="3" class="space"></td>
            </tr>

            <tr v-if="paymentSegment.reductionLineItems.length">
              <td colspan="2" class="reducible-label">Reducible Amount{{paymentSegment.reducibleAmountExplanation ? paymentSegment.reducibleAmountExplanation : ''}}</td>
              <td :class="'amount ' + evenOrOdd('reset') + amountClass(paymentSegment.reducibleAmount)">{{paymentSegment.reducibleAmount}}</td>
              <td colspan="3" class="space"></td>
            </tr>
            <tr>
              <td colspan="3" class="period-benefit-label">Period Benefit</td>
              <td :class="'amount period-benefit-amount ' + evenOrOdd('reset')">{{paymentSegment.netBenefit}}</td>
              <td colspan="2" class="space"></td>
            </tr>

            <tr>
              <td colspan="6" class="payment-segment-divider"></td>
            </tr>
          </template>
          <tr v-if="payrollPeriod.carryoverAdjustment">
            <td colspan="3" class="payroll-carryover-label">{{payrollPeriod.carryoverAdjustmentDescription ? payrollPeriod.carryoverAdjustmentDescription : 'Adjustment'}}</td>
            <td :class="'amount ' + evenOrOdd('reset') + amountClass(payrollPeriod.carryoverAdjustment)">{{payrollPeriod.carryoverAdjustment | currencyFormat}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="payrollPeriod.carryoverAdjustment">
            <td colspan="6" class="payment-segment-divider"></td>
          </tr>
          <tr>
            <td colspan="3" class="payroll-subtotal-label">Total Gross Benefit</td>
            <td :class="'amount ' + evenOrOdd('reset') + amountClass(payrollPeriod.netBenefitAfterCarryoverAdjustment)">{{payrollPeriod.netBenefitAfterCarryoverAdjustment}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="isAdjustment && notZero(payrollPeriod.previouslyPaid)">
            <td colspan="3" class="payroll-subtotal-label">Previously Paid <span v-if="payment.supersededTransactionsComment">("{{payment.supersededTransactionsComment}}")</span></td>
            <td :class="'amount previously-paid-amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.previouslyPaid)">{{payrollPeriod.previouslyPaid}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-for="redirect in payrollPeriod.preTaxRedirects">
            <td colspan="3" class="redirect-label">{{redirect.comment}}</td>
            <td :class="'amount redirect-amount ' + evenOrOdd('flip') + amountClass(redirect.amountFormatted)">{{redirect.amountFormatted}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="payrollPeriod.preTaxRedirects && payrollPeriod.preTaxRedirects.length">
            <td colspan="3" class="payroll-subtotal-label">Taxable</td>
            <td :class="'amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.netBenefitAfterPreTaxRedirects)">{{payrollPeriod.netBenefitAfterPreTaxRedirects}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="notZero(payrollPeriod.fitTax)">
            <td colspan="3" class="payroll-subtotal-label">Federal Income Tax</td>
            <td :class="'amount tax-amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.fitTax, true)">{{payrollPeriod.fitTax}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="notZero(payrollPeriod.fissTax)">
            <td colspan="3" class="payroll-subtotal-label">Social Security</td>
            <td :class="'amount tax-amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.fissTax, true)">{{payrollPeriod.fissTax}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="notZero(payrollPeriod.fimcTax)">
            <td colspan="3" class="payroll-subtotal-label">Medicare</td>
            <td :class="'amount tax-amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.fimcTax, true)">{{payrollPeriod.fimcTax}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="notZero(payrollPeriod.sitTax)">
            <td colspan="3" class="payroll-subtotal-label">State Income Tax</td>
            <td :class="'amount tax-amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.sitTax, true)">{{payrollPeriod.sitTax}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="notZero(payrollPeriod.sdiTax)">
            <td colspan="3" class="payroll-subtotal-label">SDI</td>
            <td :class="'amount tax-amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.sdiTax, true)">{{payrollPeriod.sdiTax}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-if="notZero(payrollPeriod.vdiTax)">
            <td colspan="3" class="payroll-subtotal-label">VDI</td>
            <td :class="'amount tax-amount ' + evenOrOdd('flip') + amountClass(payrollPeriod.vdiTax, true)">{{payrollPeriod.vdiTax}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr v-for="redirect in payrollPeriod.postTaxRedirects">
            <td colspan="3" class="redirect-label">{{redirect.comment}}</td>
            <td :class="'amount redirect-amount ' + evenOrOdd('flip') + amountClass(redirect.amountFormatted)">{{redirect.amountFormatted}}</td>
            <td colspan="2" class="space"></td>
          </tr>
          <tr>
            <td colspan="4" class="payroll-subtotal-label">Total Payroll Payment ({{payrollPeriod.fromDate}}-{{payrollPeriod.throughDate}})</td>
            <td :class="'amount total-payroll-period-payment-amount ' + evenOrOdd('reset') + amountClass(payrollPeriod.netBenefitAfterRedirectsAndTaxes)">{{payrollPeriod.netBenefitAfterRedirectsAndTaxes}}</td>
            <td class="space"></td>
          </tr>
          <tr>
            <td colspan="6" class="payroll-period-divider"></td>
          </tr>
        </template>

        <tr>
          <td colspan="5" class="payment-subtotal-amount-label">Total Gross Benefit</td>
          <td :class="'amount total-payroll-period-payment-amount ' + evenOrOdd('reset') + amountClass(payment.grossAmountFormatted)">{{payment.grossAmountFormatted}}</td>
        </tr>
        <tr v-if="isAdjustment">
          <td colspan="5" class="payment-subtotal-amount-label">Total Previously Paid <span v-if="payment.supersededTransactionsComment">("{{payment.supersededTransactionsComment}}")</span></td>
          <td :class="'amount previously-paid-amount ' + evenOrOdd('flip') + amountClass(payment.totalPreviouslyPaidFormatted)">{{payment.totalPreviouslyPaidFormatted}}</td>
        </tr>
        <tr v-if="notZero(payment.totalRedirectsFormatted)">
          <td colspan="5" class="payment-subtotal-amount-label">Total Redirects</td>
          <td :class="'amount redirect-amount ' + evenOrOdd('flip') + amountClass(payment.totalRedirectsFormatted)">{{payment.totalRedirectsFormatted}}</td>
        </tr>
        <tr v-if="notZero(payment.taxAsNegativeFormatted)">
          <td colspan="5" class="payment-subtotal-amount-label">Total Taxes</td>
          <td :class="'amount tax-amount ' + evenOrOdd('flip') + amountClass(payment.taxesAsNegativeFormatted, true)">{{payment.taxAsNegativeFormatted}}</td>
        </tr>
        <tr>
          <td colspan="5" class="total-payment-amount-label">Total Payment Amount ({{payment.periodStartDate}} - {{payment.periodEndDate}})</td>
          <td :class="'amount total-payment-amount ' + evenOrOdd('flip') + amountClass(payment.totalFormatted)">{{payment.totalFormatted}}</td>
        </tr>
      </table>

      <b-row class="col-highlight footer" v-if="!payment.detailedBenefitPayment">
        <b-col cols="3"></b-col>
        <b-col class="payment-detail-key font-weight-bold text-right" cols="6">Payment Subtotal:</b-col>
        <b-col class="payment-detail-value p-0" cols="3">{{ payment.paymentSubtotalFormatted }}</b-col>
      </b-row>
    </b-container>

    <b-container fluid class="col-data-box p-0 mt-4" v-if="!payment.detailedBenefitPayment">
      <b-row class="col-highlight header mb-3">
        <b-col cols="9" class="p-0">Tax Item</b-col>
        <b-col cols="3" class="p-0">Amount</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="9">Federal Income Tax</b-col>
        <b-col class="payment-detail-value" cols="3">{{ payment.fitTaxFormatted }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="9">FISS Tax</b-col>
        <b-col class="payment-detail-value"  cols="3">{{ payment.fissTaxFormatted }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="9">FIMC Tax</b-col>
        <b-col class="payment-detail-value" cols="3">{{ payment.fimcTaxFormatted }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="9">State Income Tax</b-col>
        <b-col class="payment-detail-value" cols="3">{{ payment.sitTaxFormatted }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="9">State Disability Insurance Tax</b-col>
        <b-col class="payment-detail-value" cols="3">{{ payment.sdiTaxFormatted }}</b-col>
      </b-row>
      <b-row>
        <b-col class="payment-detail-key" cols="9">Voluntary Disability Insurance Tax</b-col>
        <b-col class="payment-detail-value" cols="3">{{ payment.vdiTaxFormatted }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="9" class="text-right payment-detail-key extra-padding">Tax Subtotal:</b-col>
        <b-col class="payment-detail-value" cols="3">{{ payment.taxSubtotalFormatted }}</b-col>
      </b-row>
      <b-row class="col-highlight footer">
        <b-col cols="3"></b-col>
        <b-col  cols="6" class="text-right payment-detail-key">Total:</b-col>
        <b-col  cols="3" class="p-0">{{ payment.totalFormatted }}</b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import get from 'just-safe-get'

  let evenOddStatus = false;

  export default {
    name: 'payment',
    props: ['paymentId'],
    data: function() {
      return {
        payment: {},
        errors: []
      }
    },
    methods: {
      evenOrOdd: function(op) {
        if (op === 'reset') {
          evenOddStatus = false;
        }
        else if (op === 'flip') {
          evenOddStatus = !evenOddStatus;
        }

        return evenOddStatus ? 'even' : 'odd';
      },
      amountClass: function(amount, inverse) {
        let amountNum = this.asFloat(amount);
        if (!amountNum || isNaN(amountNum)) return 'zero-currency';
        if (inverse) amountNum = 0 - amountNum;
        return ' ' + (amountNum == 0.0 ? 'zero-currency' : (amountNum > 0 ? 'pos-currency' : 'neg-currency'));
      },
      notZero: function(amountString) {
        const fl = this.asFloat(amountString);
        return !isNaN(fl) && fl != 0;
      },
      asFloat: function(amountString) {
        if (!amountString) return amountString;
        amountString = amountString.replace('$', '');
        amountString = amountString.replace(',', '');
        return parseFloat(amountString);
      }
    },
    computed: {
      logoSrc() {
        return get(this.payment, 'client.logoBase64')!==undefined ? "data:image/gif;base64," + get(this.payment, 'client.logoBase64') : null;
      },
      isAdjustment: function() {
        return this.payment && this.payment.supersededTransactionIds && this.payment.supersededTransactionIds.length > 0;
      }
    },
    created() {
      this.$http.get(ApiProperties.api.payment, { pathVars: { paymentId: this.paymentId }}).then(response => {
        this.payment = response.data;
      }).catch(e => {
        this.errors.push(e);
      })
    },
  }
</script>
<style lang="scss">
  @import "../scss/variables";

  .row.col-highlight.header {
    font-size: 14px !important;
    font-weight: $font-weight-bold !important;
    border-radius: 4px !important;
    padding: 8px;
    background: #2D3B3E !important;
  }

  .row.col-highlight.footer {
    font-size: 14px !important;
    background-color: #ECE7E0 !important;
  }
  .payment-details .payment-line-items:nth-of-type(2n+1) {
    background: #ECE7E0 !important;
  }

  .payment-detail-key {
    font-size: 14px;
    font-weight: $font-weight-bold !important;
  }

  .payment-detail-value {
    font-size: 16px;
    font-weight: $font-weight-light;
  }

  .payment-details .payment-line-items:nth-of-type(2n+1){
    background: #ECE7E0;
  }

  .payment-details {
    address {
      font-size: 16px;
      font-weight: $font-weight-light;
    }

    .payment-line-items {
      border-radius: 10px;
    }

    .payment-line-items:nth-of-type(2n+1) {
      background-color: #efefef;
    }

    table.payment-detail {
      margin-top: 1em;
    }

    table.payment-detail tr td {
      border: 2px solid black;
      font-weight: bold;
      padding: 0.2em;
    }

    .payment-segment-dates, .period-benefit-label {
      background: #cacaca;
    }

    .payroll-subtotal-label, .redirect-label {
      background: #BBBBBB;
    }

    .payment-subtotal-amount-label, .total-payment-amount-label {
      color: white;
      background: #999999;
    }

    .total-payment-amount-label, .total-payment-amount {
      font-size: larger;
      font-weight: bold;
    }

    .amount {
      text-align: right !important;
    }


    td.even {
      background: #DDDDDD !important;
    }

    td.odd {
      background: #eeeeee !important;
    }


  }
</style>